<template>
  <div>
    <div v-if="loading" class="flex sm12 lg12">
      <div class="text--center pb-4">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <!-- Notifications -->
      <!-- Edit and duplicate messages -->
      <div class="mb-3">
        <va-notification
          :value="showNotification"
          :info="{level: 0}"
          closeable
        >
          <va-badge>{{ notificationType }}</va-badge>
          {{ $t('growing.recipe') + ' ' + storedRecipeNumber + ' ' +  notificationMessage}}
        </va-notification>
      </div>
      <!-- Delete messages -->
      <div class="mb-3">
        <va-notification v-model="successNotificationVisible" closeable>
          <va-badge>
            {{ $t('notificationsPage.alerts.success') }}
          </va-badge>
          {{ $t('growing.recipeEdition.deleteSuccessfullyMessage') }}
        </va-notification>
      </div>
      <div class="mb-3">
        <va-notification v-model="errorNotificationVisible" color="danger" input closeable>
          <va-badge color="danger">
            {{ $t('notificationsPage.alerts.error') }}
          </va-badge>
          {{ $t('growing.recipeEdition.deleteErrorMessage') }}
        </va-notification>
      </div>

      <div class="flex xs12 sm12">
        <va-card
          :style="computedStyle"
          :class="computedClass"
          :title="$t(`menu.growing-recipes`)">

          <template slot="actions">
            <router-link :to="{ name: 'recipe-growing-new', params: { recipeId: 0, displayName: $t('menu.recipe-growing-create') }}">
              <va-button flat icon="at-icon card_plus">{{ $t('growing.createRecipe') }}</va-button>
            </router-link>
          </template>

          <!-- Table -->
          <at-data-table
            ref="table"
            :apiMode="apiMode"
            :apiUrl="apiUrl"
            :fields="fields"
            :defaultPerPage="defaultPerPage"
            :dataPath="dataPath"
            :paginationPath="paginationPath"
            :queryParams="queryParams"
            :filterQuery="filterQuery"
            :filter-input-shown="false"
            :per-page-selector-shown="false"
          >
            <template slot="recipeName" slot-scope="props">
              <p @click="showRecipe(props.rowData)" style="cursor: pointer;">{{ props.rowData.name }}</p>
            </template>

            <template slot="edit" slot-scope="props">
              <router-link :to="{ name: 'recipe-growing-edit', params: { recipeId: props.rowData.id, displayName: $t('menu.recipe-growing-edit') }}">
                <va-button flat icon='at-icon l_edit1'/>
              </router-link>
            </template>

            <template slot="duplicate" slot-scope="props">
              <router-link :to="{ name: 'recipe-growing-duplicate', params: { recipeId: props.rowData.id, displayName: $t('menu.recipe-growing-duplicate') }}">
                <va-button flat icon='at-icon l_duplicate_recipe'/>
              </router-link>
            </template>

            <template slot="delete" slot-scope="props">
              <va-button flat icon='at-icon l_close1' color="danger" @click="confirmDelete(props.rowData)"/>
            </template>

          </at-data-table>
        </va-card>
      </div>
      <va-modal
        class="recipes-modal"
        v-model="showInModal"
        :noOutsideDismiss="false"
        :hideDefaultActions="true"
        size="large"
        ref="modal"
      >
        <at-recipe-timeline @cancel="cancelModal"
          :growing-recipe-encrypted="seeRecipe"
          :is-production-recipe="false"
          :is-in-modal="true"
          :editable="false"
        />
      </va-modal>
      <va-modal
        v-model="showDeleteModal"
        :title=" $t('growing.recipeEdition.delete') "
        :message=" $t('growing.recipeEdition.deleteMessage') "
        :noOutsideDismiss="true"
        :hideDefaultActions="true"
        ref="modal"
      >
        <div class="flex xs8 ml-5">
          <va-input
            v-model="actionControlString"
            type="string"
            :placeholder="$t('growing.recipeEdition.confirmDelete')"
          />
        </div>
        <template slot="actions">
          <div class="flex xs12 text-right">
            <va-button @click="cancelModal()" flat color="gray">
              {{ $t('modal.cancel') }}
            </va-button>
            <va-button :disabled="actionControlString !== 'DELETE'" @click="remove()" color="success">
              {{ $t('modal.confirm') }}
            </va-button>
          </div>
        </template>
      </va-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { structuredApiUrl } from '../../../utilities/socketio'

export default {
  name: 'recipes-growing',
  data () {
    return {
      loading: false,
      term: null,
      apiMode: true,
      apiUrl: `${structuredApiUrl}/growing-recipes/`,
      dataPath: 'data',
      paginationPath: 'pagination',
      defaultPerPage: '15',
      queryParams: {
        sort: 'sort',
        page: 'page',
        perPage: 'per_page',
      },
      filterQuery: 'search',
      seeRecipe: {},
      showInModal: false,
      showDeleteModal: false,
      selectedRecipeId: 0,
      successNotificationVisible: false,
      errorNotificationVisible: false,
      actionControlString: '',
    }
  },
  created () {
    this.$level.value = 'airplane'
  },
  mounted () {
  },
  computed: {
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger',
      }
    },
    computedStyle () {
      const style = {
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: cardGradient(this.$themes.primary, this.$status.value, this.$mode.value),
        // boxShadow: genShadow(this.$themes.primary, this.$status.value, this.$mode.value),
        // borderRadius: genRadius({ topLeft: '24px', bottomRight: '24px' }),
      }
      return style
    },
    showNotification () {
      return this.$store.getters.getSaveRecipe.showState
    },
    notificationType () {
      return this.$store.getters.getSaveRecipe.success ? this.$t('notificationsPage.alerts.success') : this.$t('notificationsPage.alerts.error')
    },
    notificationMessage () {
      const message = this.$store.getters.getSaveRecipe.message
      return this.$t(`growing.recipeEdition.${message}`)
    },
    storedRecipeNumber () {
      return this.$store.getters.getSaveRecipe.recipeNumber
    },
    fields () {
      return [
        {
          name: '__slot:recipeName',
          title: this.$t('tables.headings.recipe'),
          sortField: 'name',
          width: '70%',
        },
        {
          name: '__slot:edit',
          title: this.$t('tables.headings.edit'),
          dataClass: 'text-center',
          width: '10%',
        },
        {
          name: '__slot:duplicate',
          title: this.$t('tables.headings.duplicate'),
          dataClass: 'text-center',
          width: '10%',
        },
        {
          name: '__slot:delete',
          title: this.$t('tables.headings.delete'),
          dataClass: 'text-center',
          width: '10%',
        },
      ]
    },
  },
  methods: {
    confirmDelete (recipe) {
      this.showDeleteModal = true
      this.selectedRecipeId = recipe.id
    },
    remove () {
      axios.delete(`${structuredApiUrl}/growing-recipes/${this.selectedRecipeId}`)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            this.successNotificationVisible = true
            this.$refs.table.$refs.vuetable.refresh()
          } else {
            this.errorNotificationVisible = true
          }
        })
        .catch(function (error) {
          console.log(error)
        })

      this.selectedRecipeId = 0
      this.$refs.modal.close()
    },
    showRecipe (recipe) {
      this.showInModal = true
      this.seeRecipe = recipe
    },
    cancelModal () {
      this.$refs.modal.cancel()
    },
  },
}
</script>

<style lang="scss">
.va-data-table__vuetable thead tr th:first-child {
  font-size: 1rem !important;
  text-align: left;
}

.va-data-table__vuetable thead tr th {
  font-size: 1rem !important;
  text-align: center;
}

.vuetable-slot {
  p {
    font-size: 1rem !important;
  }

  .va-button {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.5rem;
  }
}

.va-input__container__input {
  padding-bottom: 0.1em !important;

  &::placeholder {
    text-transform: none;
  }
}

.va-modal__overlay.recipes-modal {
  .va-modal.va-modal--size-large {
    width: 90vw;
    max-width: 90vw;

    .va-modal__inner {
      max-width: 100%;
      padding: 0;

      .title {
        margin-left: 1.25rem;
        font-size: 1rem;
      }
    }

    .va-modal__message {
      width: 100%;
    }
  }
}
</style>
